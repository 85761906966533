
body, html {
  height: 100%;
  overflow-x: hidden;
}
#root{
  height: 100%;
}
.main-page{
  min-height: 100%;
  background-color: #bdbdbd7e;
  padding-bottom: 2rem;
}


#brandDiv{
  height: 100px;
  width: 100%;
  background: rgb(255,45,45);
  background: linear-gradient(90deg, rgba(255,255,255,1) 33%, rgba(255,76,76,1) 89%, rgba(255,46,46,1) 99%);
}
#brandLabel{/* padding-top: 100px; */font-size: 1.5em;font-style: italic;margin-left: 43px;padding-top: 35px; font:  "sans-serif"}

.nav-link{
  font-size:1.5em;
  border-left:solid;
  border-width: 1px;
  /* margin-left: 11px; *//* padding-left: 7px; */
  padding-top: 4px;
  border-color: #e9ecef;
}

.nav-item{padding:0.5em;padding-left: 0;padding-right: 0; border-bottom: solid 4px;
   /* border: 2px; */
   border-color: #ffffff;
    -webkit-transition : border 500ms ease-in-out; 
 -moz-transition : border 500ms ease-in-out;
 -o-transition : border 500ms ease-in-out;
}

.clickable:hover{

  cursor: pointer
}


.nav-item:hover{
   border-bottom: solid 4px;
   /* border: 2px; */
   border-color: rgb(255, 45, 45);

   }

.bg-light{
    background-color: #ffffff!important;
    /* border-bottom: solid 5px rgb(255, 45, 45); */
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(64, 62, 62);
}
.navbar-nav {
  flex-direction: row;
}
.active{
  border-bottom: solid 4px rgb(255, 45, 45);
  background-color: #e9ecef;
}

#navbar{
    /* border-bottom: 2px; */
    /* border: solid; */
    border-top: 0;
    /* margin-bottom: 0; */
    border-left: 0;
    /* border-right: 0; */
    /* border-color: rgb(255, 45, 45); */
    margin: 0px;
    padding: 0;
}
.btn-outline-success {
    color: rgb(255, 45, 45);
    border-color: rgb(255, 45, 45);
}

.btn-primary{
  color:white;
  border-color:rgb(255, 45, 45);
  background-color:rgb(255, 45, 45);
}
.btn-primary.disabled{
  color:white;
  border-color:rgb(255, 45, 45);
  background-color:rgb(255, 45, 45);
}
.btn-primary:disabled{
  color:white;
  border-color:rgb(255, 45, 45);
  background-color:rgb(255, 45, 45);
}

.btn-primary:hover{
  background-color:rgb(255, 45, 45);
  border-color:#ff2d2d;
}
#message-card{
    margin-top: 5.5%;
}

#navigation-arrows{
    text-align: right;
}



#bottom-menu{height: 102px;background-color: #a5a5a5;margin-top:5px;}

#photo-carousel
{
  margin-top: 1%;
  height: 90%;
}
.titulo-carousel-flota{
  color: rgb(255, 45, 45);
  font-size: 2rem;
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  border-bottom: solid rgb(255, 45, 45);

}

.row-second-index{
  background-color: #f7f7f7;
  
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-top: solid rgb(255, 45, 45);
}
.row-third-index{
  background-color: #f7f7f7;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

}

.tarjetas-container-index{

  background-color: white;
    padding: 1rem;
    margin: 1rem;
}

#titulo-tarjetas-index{

  margin:1rem

}
#carousel-image{
  height:100%;
}



@media (max-width: 768px) {
    
    [class*="col-"] {
      max-width: 100%;
    }

    #brandDiv{
      height: 100px;
      width: 100%;
      color:white;
      background: #fff;
      /*background: linear-gradient(270deg, rgba(255,45,45,1) 20%, rgba(255,55,55,1) 26%, rgba(255,56,56,1) 29%, rgba(255,57,57,1) 33%, rgba(255,58,58,1) 40%, rgba(255,70,70,1) 44%, rgba(255,76,76,1) 49%, rgba(255,82,82,1) 51%, rgba(255,101,101,1) 56%, rgba(255,119,119,1) 61%, rgba(255,156,156,1) 66%, rgba(255,206,206,1) 73%, rgba(255,255,255,0.7626400902157738) 82%, rgba(255,255,255,1) 84%, rgba(255,255,255,1) 92%);}
    */
    }
    #first-column{
        display:none;
    }

    #photo-carousel{
        display:none;
        /* height: 700px!important; */
    }
    .nav-item{
      padding:0.5em;
      
       border-bottom: solid 4px;
      /* border: 2px; */
      border-color: #ffffff;
       font-size: 0.8rem
   }
   .active{
    border-bottom: solid 4px rgb(255, 45, 45);
    }
    .responsive{
      margin-left:5px!important;
    }
    .main-page {
      min-height: 90%;
   
  }
  .horariosRuta{
    display:none
  }
  .col-ruta{
    margin-left : 0!important;
    margin-bottom: 0!important;
  }
  .barra-botones{
    margin: 0;
    min-width: 205px;
  }
}
  #bottom-menu > div{color:white;text-align: center;margin: auto;font-size: 1.8em;/* border-left: solid; *//* height: 100%; */}
  @media (max-width: 768px) {
    #bottom-menu{height: 218px;background-color: #a5a5a5;}
    #bottom-menu > div{color:white;text-align: center;margin: 0;/* border-left: solid; */font-size: 2em!important;/* border-bottom: solid 4px #fff; *//* border-top: solid 4px #fff; */border: solid 4px #fff;}
    .blank-option{display:none;}
    #loginButton{
      margin-left: 10px;

    }
    #responsive-card{
      margin-bottom: 3rem;
      min-height: 0%!important;
    }
    #registerLink{
      display:inline-block;
      float:left;
      margin-top:15px;
      margin-left:20px;
      margin-right:20px;
    }
    
    .nav-link{
      border:0px
    }
    #logoutButton{
      margin-right: 3px!important;
      margin-left: 28px;
      float: left;
      margin-top: 11px!important;
      margin-bottom: 15px!important;
  }#configButton{
    margin-right: 3px!important;
    margin-left: 28px;
    float: left;
    margin-top: 11px!important;
    margin-bottom: 15px!important;
  }
  #barraPedanias{
    display:none
  }
  #selectorPedanias{

    display:block!important;
    min-width: 292px;
    margin-left: 12%;
    
  
  }
  .anuncio-texto{
    min-height: 20rem;
    max-height: 20rem
  }
}
@media (max-width: 500px) {
  #responsive-card{
    margin-bottom: 3rem;
    
  }
}

#mapa-web{
    height: 300px;
    background-color: black;
    margin-top: 5px;
}
  
#failedLoginMessage{background-color: #ff000059;color: #bb0404;margin-top: 5px;/* display: inline-block; */padding: 3px;padding-left: 17px;padding-right: 10px;border-left: solid;display: none;}

#registerUsername{margin-right: 15px;}

.btn-outline-success:hover{
  background-color: rgb(255, 45, 45);
  border-color: rgb(255, 45, 45)

}
#configButton:hover{
  background-color: rgb(204, 192, 192);
  border-color: rgb(95, 93, 93);
}
#configButton:focus{
  box-shadow: 0 0 0 0.2rem rgb(204, 186, 186);
}
.btn-outline-success.focus, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 45, 45, 0.38);
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: rgb(255, 45, 45);
    border-color: rgb(255, 45, 45);
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(255, 45, 45);
  border-color: rgb(255, 45, 45);
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: rgb(255, 45, 45);
  border-color: rgb(255, 45, 45);
  box-shadow: 0 0 0 0.2rem rgb(255, 45, 45,.5);
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(255, 45, 45,.5);
}
#registerLink{
  margin-right:20px;
}
#loginButton{
  margin-right:20px
}
#logoutButton{
    margin-right: 11px;
}
#configButton{
  margin-right: 11px;
  color: #464545;
  border-color: grey;
}

#failedLogin{
  display:none;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color:rgb(255, 45, 45);
  border-color: rgb(255, 45, 45);

}

#selectorPedanias{

  display:none

}

.anuncio-texto{
  min-height: 25rem;
  max-height: 25rem;
}


/* Estilos del componente Ruta*/

.container-ruta{
  background-color: white
}

.parada{

  height: 7rem;
    vertical-align: middle;

}
.parada:after{
  content: "";
  position: absolute;
  bottom: 34%;
  left: 45.5%;
  right: 0;
  border-top: 5px solid black;
  z-index: 0;
  transform: rotate(90deg);
  width: 27px;
  border-radius: 11px;
}
.parada:before{
  content: "";
  position: absolute;
  bottom: 34%;
  left: 45.5%;
  right: 0;
  border-top: 5px solid red;
  z-index: 0;
  transform: rotate(90deg);
  width: 27px;
  border-radius: 11px;
}

.ruteStep{
  width: 27px;
  height: 27px;
  background-color: rgb(255, 45, 45);
  border-radius: 100%;
  display:inline-block
}

.nombreParada{
  padding-top:31px;

}
.nombreParada:after{
  content: "";
  position: absolute;
  bottom: 34%;
  left: 45.5%;
  right: 0;
  z-index: 0;
 
  width: 27px;
  height: 27px;
  border-radius: 100%;
  background-color:  rgb(255, 45, 45)
}

.li-pedanias{
  border:none;
  border-radius:0px;

}

.li-pedanias:hover{
  border-left: solid 3px rgb(255, 45, 45);
  background-color: #e9ecef
}
.li-pedanias-selected{
  border-left: solid 3px rgb(255, 45, 45);
  background-color: #e9ecef
}



/* Estilos pedanias tabs */
.main-container{
  
  background-color: white;
  border: 0.5px solid whitesmoke;
  padding: 10px;
}



.tabs-container{
  
  
  width:100%;
 
}

.react-tabs__tab {
display: inline-block;
border: 1px solid transparent;
border-bottom: none;
bottom: -1px;
position: relative;
list-style: none;
padding: 6px 12px;
cursor: pointer;
text-align: center;
font-size: 1.5rem;
  width: 50%;
  color: #676767;
}
.react-tabs__tab--selected {
  background: #fff;
  border-bottom: 2px solid rgb(255, 45, 45);
  transition: border-color 0.3s;
  
  color: rgb(255, 45, 45);
  border-radius: 5px 5px 0 0;
 
}
.react-tabs__tab-list {
  border-bottom: 2px solid white;
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab-panel {
  opacity: 0;
  -webkit-transform: translateX(1%);
  transform: translateX(1%);
  display: block

}
.react-tabs__tab-panel--selected {
  
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);

  -webkit-transition: opacity 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: opacity 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: opacity 200ms ease-out, transform 200ms ease-out;
  transition: opacity 200ms ease-out, transform 200ms ease-out, -webkit-transform 200ms ease-out;
}     

/*Boton pedanias Abrir en Google Maps*/
.disabled{
  opacity: 70%;
  color: white
}
.disabled:hover{
  cursor: not-allowed;

}
.opciones{
  
  margin-top:  3rem!important;
  margin-bottom: 3rem!important;
}
@media (max-width: 768px) {
.opciones{
  margin-left: 2rem!important;
  margin-top: 0!important;
  margin-bottom:0!important;

}

.div-map{
  display: none
}
.map-container{
  height: 300px;
  margin-top: 10px
}

}
/* Pedanias - Recarga Tarjetas*/


.punto-recarga{
  padding-bottom: 0;
  border-bottom: solid 0.2 grey;
  border-top: none;
  border-left: none;
  border-right: none;
  
}
.punto-recarga:hover{
  padding-bottom: 0;
  background-color: #e9ecef;
  border-right: solid 0.5rem rgb(255, 45, 45);
  cursor: pointer
}
.punto-recarga-selected{
  border-right: solid 0.5rem rgb(255, 45, 45);
  
  padding-bottom: 0;
  background-color: #e9ecef;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem; 
   border-top-right-radius: none; 
}


.tab-recarga-tarjetas{
  min-height: 772px;
}


/* Menu colapsable tarjetas recarga*/
.Collapsible__trigger{
  display: block;
  padding: 0.8rem;
}
.Collapsible__trigger.is-open{
  display: block;
  color: white;
  background-color: rgb(255, 45, 45);
}
.list-group-item.collapsible{
  padding:0
}

.content-tarjeta{
  padding: 0.8rem
}
.seccion-tarjeta{
  display: block;
  border-bottom: 0.2px solid rgb(255, 45, 45);
  padding: 0.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}
.seccion-tarjeta.full{
  display: inline-block
}
.info-tarjeta{
  padding:0.3rem
}

/*Menu tarjetas fullWidth*/
.list-group-item.selected{
  color: white;
  background-color: rgb(255, 45, 45);
}
.menu-recarga-tarjeta li p{
  margin: 0
}
.menu-recarga-tarjeta li {
  padding: 1.3rem;
  border-radius: 0
}
.menu-recarga-tarjeta .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

}
#titulo-menu-tarjetas{

  border-top: none;
  border-left: none;
  border-right: none;

}
#titulo-menu-tarjetas p{

  border-bottom: solid 0.8px rgb(255, 45, 45);
  font-weight: bold;
  padding-bottom: 5px;
  display: inline-block;
}

.vertical-separator{

  height: 108%;
  width: 1px;
  background-color: #e6e6e6;
  position: absolute;
  top: -2%;
  left: 108%;

}
/*Contacto*/


/*Discrecionales*/
/* Define an animation behavior */
@keyframes spinner {
  to { transform: rotate(360deg); }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}


/*Estilos del componente carousel*/

/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
  .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #fff; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #fff; }

.carousel-root {
  outline: none; }

.carousel {
  position: relative;
  width: 100%; }
  .carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none; }
  .carousel .carousel {
    position: relative; }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px; }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden; }
  .carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap; }
  .carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px; }
    .carousel .thumb:focus {
      border: 3px solid #ccc;
      outline: none; }
    .carousel .thumb.selected, .carousel .thumb:hover {
      border: 3px solid #333; }
    .carousel .thumb img {
      vertical-align: top; }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden; }
    .carousel.carousel-slider .control-arrow {
      top: 0;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px; }
      .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0.2); }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in; }
    .carousel .slider-wrapper.axis-horizontal .slider {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column; }
    .carousel .slider-wrapper.axis-vertical {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-vertical .slider {
        -webkit-flex-direction: column;
        flex-direction: column; }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
    .carousel .slider.animated {
      -webkit-transition: all 0.35s ease-in-out;
      -moz-transition: all 0.35s ease-in-out;
      -ms-transition: all 0.35s ease-in-out;
      -o-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out; }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: none; }
    .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0; }
    .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0; }
    .carousel .slide .legend {
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: #fff;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      -webkit-transition: opacity 0.35s ease-in-out;
      -moz-transition: opacity 0.35s ease-in-out;
      -ms-transition: opacity 0.35s ease-in-out;
      -o-transition: opacity 0.35s ease-in-out;
      transition: opacity 0.35s ease-in-out; }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 960px) {
      .carousel .control-dots {
        bottom: 0; } }
    .carousel .control-dots .dot {
      -webkit-transition: opacity 0.25s ease-in;
      -moz-transition: opacity 0.25s ease-in;
      -ms-transition: opacity 0.25s ease-in;
      -o-transition: opacity 0.25s ease-in;
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px; }
      .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
  .carousel:hover .slide .legend {
    opacity: 1; }
